import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderBlock from "../SliderBlock";
import { ImageInterface, PortableText, MultiLineHeadline } from "../../types/SanityTypes";
import { useStaticQuery, graphql } from "gatsby";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import "./styles.scss";


export interface PlaylistSliderInterface {
  bgColor: {
    value: string;
  };
  headline: MultiLineHeadline;
  _rawSubHeading?: PortableText;
  content: {
    _rawImage: ImageInterface;
    url: string;
    title: string;
    // For Preview
    image?: ImageInterface;
  }[];
  // For Preview
  subHeading?: PortableText;
}

const PlaylistSlider: FunctionComponent<PlaylistSliderInterface> = ({
  bgColor,
  headline,
  _rawSubHeading,
  subHeading,
  content
}) => {
  const text = _rawSubHeading || subHeading;
  return (
    <section className="playlist-slider" data-testid="playlist-slider">
      <Container fluid>
        <Row>
          <Col>
            {headline.primaryText && (
              <h2
                style={{ color: bgColor.value }}
                data-testid="playlist-slider-headline"
                aria-label={
                  headline.primaryText + " " + (headline.secondaryText || "") + " " + (headline.tertiaryText || "")
                }
              >
                {headline.primaryText.toUpperCase()}{" "}
                {headline.secondaryText && (
                  <span className="move-it-1" aria-hidden="true">
                    {" "}
                    {headline.secondaryText.toUpperCase()}
                  </span>
                )}
                {headline.tertiaryText && (
                  <span className="move-it-2" aria-hidden="true">
                    {" "}
                    {headline.tertiaryText.toUpperCase()}{" "}
                  </span>
                )}
              </h2>
            )}
            <div className="sub-heading">{text && <BlockContent blocks={text} />}</div>
            {content && <SliderBlock data={content} type="playlist" />}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PlaylistSlider;
